import { useMemo } from "react";

export const displayUnassignedIssues = (specialty) => useMemo(() => {
    switch (specialty) {
      case 'Nurse Practitioner':
        return false
      case 'General Practice':
        return false
      case 'Pediatrics':
        return false
      default:
        return true
    }
  }, [specialty]
)
