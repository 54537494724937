import React, { useState } from 'react'
import PharmacySelect from './pharmacySelect'

const ExtraPharmacyButton = ({parentProfileId}) => {
  const [isVisible, setIsVisible] = useState(false)
  return (
    <div>
      <div
        className={
          !isVisible ? 'block btn btn-primary whitespace-nowrap' : 'hidden'
        }
        onClick={() => setIsVisible(!isVisible)}
      >
        Add Pharmacy
      </div>
      {isVisible && <PharmacySelect pharmacy_profile='' parentProfileId={parentProfileId} secondPharm={true} />}
    </div>
  )
}

export default ExtraPharmacyButton