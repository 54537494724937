import React, { useState } from 'react'
import { provinceOptions} from "../../utilities/provinceOptions";
import Select from 'react-select'

const ProvinceSelect = () => {
  const [selectedProvince, setProvince] = useState('')
  const urlSearchString = window.location.search
  const params = new URLSearchParams(urlSearchString)
  const loginType = params.get('login_type')

  const isDisabled = !!!selectedProvince
  return (
    <div className='form-group gap-y-4'>
      <label>Province Select<span className="text-red-700">*</span></label>
      <Select
        options={provinceOptions || []}
        onChange={setProvince}
        styles={customStyles}
        required
        menuPortalTarget={document.body}
        menuPosition={'fixed'}
      />
      <a className={isDisabled ? 'btn btn-disabled text-center' : 'btn btn-blue text-center'}
         href={isDisabled ? event.preventDefault() : whichURL(selectedProvince.value, loginType)}>Continue</a>
    </div>
  )
}

const whichURL = (province, loginType) => {
  return `https://${province}.consultmd.com/caregivers/${loginType}`
}

const customStyles = {
  control: base => ({
    ...base,
    height: 46,
    minHeight: 46,
    border: "1px solid rgb(203 213 225)",
  })
};

export default ProvinceSelect
