import React, { useMemo, useState, useEffect } from 'react'
import FormatPersonalHealthNumber from '../../shared_components/formatPersonalHealthNumber'
import GenderSelect from "../../shared_components/genderSelect"
import Select from "react-select";

const PatientDemographics = ({ previousPatients, draftProfile }) => {
  const [selectedPatientProfile, setSelectedPatientProfile] = useState({})
  const [firstName, setFirstName] = useState( draftProfile?.first_name || "")
  const [lastName, setLastName] = useState(draftProfile?.last_name || "")
  const [dateOfBirth, setDateOfBirth] = useState(draftProfile?.dob ||'')
  const [gender, setGender] = useState(draftProfile?.gender && {label: draftProfile?.gender, value: draftProfile?.gender} || {})
  const [personalHealthNumber, setPersonalHealthNumber] = useState(draftProfile?.personal_health_number ||'')


  const patientProfiles = useMemo(() => (
    previousPatients?.map((profile) => (
      {
        label: `${profile.name} | dob: ${profile.dob} `,
        value: profile.id,
        firstName: profile.first_name,
        lastName: profile.last_name,
        dob: profile.dob,
        personalHealthNumber: profile.personal_health_number,
        gender: profile.gender
      }
    ))
  ), [previousPatients])

  const handleClearForm = () => {
    setSelectedPatientProfile({firstName: '', lastName: '', dob: '', personalHealthNumber: '', gender: '', id: null})
  }

  useEffect(() => {
    setFirstName(selectedPatientProfile?.firstName)
    setLastName(selectedPatientProfile?.lastName)
    setDateOfBirth(selectedPatientProfile?.dob)
    setGender({ label: selectedPatientProfile?.gender, value: selectedPatientProfile?.gender })
    setPersonalHealthNumber(selectedPatientProfile?.personalHealthNumber)
  }, [selectedPatientProfile])

  return (
    <div
      className='flex flex-col xl:flex-row items-center gap-x-2 justify-center w-full overflow-x-auto p-1 md:p-8 bg-slate-100'>
      <div className='form-group'>
        <label>Previous Patients</label>
        <Select
          options={patientProfiles || []}
          onChange={setSelectedPatientProfile}
          styles={customStyles}
          menuPortalTarget={document.body}
          menuPosition={'fixed'}
          value={selectedPatientProfile}
        />
      </div>
      <div className='form-group'>
        <label>First Name<span className="text-red-700">*</span></label>
        <input
          type='text'
          placeholder='Jane'
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          required
        />
        <input type='hidden'
               name={`issue[patient_profile_attributes][first_name]`}
               value={firstName} />
        <input type='hidden'
               name={`issue[patient_profile_attributes][id]`}
               value={selectedPatientProfile?.value} />
      </div>
      <div className='form-group'>
        <label>Last Name<span className="text-red-700">*</span></label>
        <input
          type='text'
          placeholder='Doe'
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          required
        />
        <input type='hidden'
               name={`issue[patient_profile_attributes][last_name]`}
               value={lastName} />
      </div>
      <div className='form-group'>
        <label>Date of Birth<span className="text-red-700">*</span></label>
        <input type='date' value={dateOfBirth} onChange={(e) => setDateOfBirth(e.target.value)} />
        <input type='hidden'
               name={`issue[patient_profile_attributes][dob]`}
               value={dateOfBirth} />
      </div>
      <div className='form-group'>
        <GenderSelect gender={gender} setGender={setGender} />
      </div>
      <div className='form-group'>
        <label>Personal Health Number<span className="text-red-700">*</span></label>
        <FormatPersonalHealthNumber personalHealthNumber={personalHealthNumber}
                                    setPersonalHealthNumber={setPersonalHealthNumber} />
      </div>
      <span onClick={handleClearForm} className='text-xs underline cursor-pointer'>Reset form</span>
    </div>
  )
}


const customStyles = {
  control: base => ({
    ...base,
    height: 46,
    minHeight: 46,
    border: "1px solid rgb(203 213 225)",
  })
};

export default PatientDemographics
