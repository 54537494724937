import React from 'react'
import NumberFormat from 'react-number-format'

const FormatPhoneNumber = ({ fieldName, value }) => {
  return (
    <NumberFormat
      name={fieldName}
      format='(###) ###-####'
      mask='_'
      value={value || ''}
    />
  )
}

export default FormatPhoneNumber
