import React, { useState } from 'react';
import { createIssue, updateReport } from "./helpers";
import TextInput from "./textInput";
import FilesList from "./filesList";
import ReassignModal from "./reassignModal";

const Edit = ({ issue, report }) => {
  const [note, setNote] = useState(report?.note || '');
  const [impression, setImpression] = useState(report?.impression || '');
  const [suggestions, setSuggestions] = useState(report?.suggestions || '');
  const [files, setFiles] = useState([]);
  const [hasFiles, setHasFiles] = useState(report?.file_attachments?.length > 0);
  const [showModal, setShowModal] = useState(false)

  const handleFileChange = (event) => {
    setFiles(event.target.files);
  };

  const handleSubmit = async (type) => {
    const formData = new FormData();
    formData.append('consultation_report[note]', note)
    formData.append('consultation_report[impression]', impression)
    formData.append('consultation_report[suggestions]', suggestions)
    Array.from(files).forEach((file) => {
      formData.append('consultation_report[files][]', file);
    });
    formData.append('type', type)

    let confirmed = false

    const missingFields = note.length < 1 || impression.length < 1 || suggestions.length < 1

    if (missingFields) {
      window.alert('Please make sure all fields are filled out')
    }

    if (type == 'Send Consultation' && !missingFields) {
      confirmed = window.confirm("I have reviewed my report and ready to submit it to the requesting physician.")
    }

    if (type == 'Save Draft') { confirmed = true }

    if (confirmed) {
      try {
        let response;
        if (report && report.id) {
          response = await updateReport(issue, report, formData);
        } else {
          response = await createIssue(issue, formData);
        }
        window.location.href = response.data.path;
      } catch (error) {
        console.error('Error updating report:', error);
      }
    }
  };

  return <>
    <ReassignModal showModal={showModal} setShowModal={setShowModal} issue={issue}/>
    <div className="relative flex flex-col break-words w-full bg-white">
      <div className="rounded-t mb-0 py-3 border-0">
        <div className="flex flex-wrap items-center">
          <div
            className="relative w-full flex flex-col md:flex-row items-center md:justify-center max-w-full flex-grow flex-1 text-slate-700 gap-y-2">
            <h3 className="uppercase font-semibold text-center w-full text-xl">
              Consultation Report
            </h3>
          </div>
        </div>
      </div>
      <div className="bg-slate-100 p-4 flex flex-col text-base w-full">
        <TextInput title={'Impression'} label={impression} setLabel={setImpression}/>
        <TextInput title={'Note'} label={note} setLabel={setNote}/>
        <TextInput title={'Suggestions'} label={suggestions} setLabel={setSuggestions}/>
        <div className="flex flex-col gap-x-1 mb-1">
          <label className="mb-1 font-semibold text-slate-600 uppercase">Upload Images</label>
          <input type="file" multiple onChange={handleFileChange}/>
        </div>
        <FilesList hasFiles={hasFiles} report={report} />
        <div className="p-4 flex flex-row items-center justify-center gap-x-4">
          <button className="btn btn-red" onClick={() => setShowModal(true)}>
            Reassign
          </button>
          <button className="btn btn-export" onClick={() => handleSubmit('Save Draft')}>
            Save Draft
          </button>
          <button className="btn btn-default" onClick={() => handleSubmit('Send Consultation')}>
            Send Consultation
          </button>
        </div>
      </div>
    </div>
  </>
};

export default Edit;
