/* Generic URL helpers */
const urlParts = () => {
  const { pathname, search } = new URL(window.location.href)
  const pathParts = pathname.split('/').filter(e => e !== '')
  return { pathname, pathParts, search }
}

const urlMatch = (url) => {
  return url === `${urlParts().pathname}${urlParts().search}`
}

/* User role path identifiers */
const includesPatientProfiles = () => urlParts().pathParts.includes('patient_profiles')
const includesCaregivers = () => urlParts().pathParts.includes('caregivers')

const isCurrentPathCaregiver = (url) => {
  if (!includesCaregivers()) return false
  return urlMatch(url)
}

const isCurrentPathPatient = (url) => {
  if (!includesPatientProfiles()) return false
  return urlMatch(url)
}

/* User role navbar highlighter */
export const highlightCurrentPath = (url) => {
  if (isCurrentPathCaregiver(url)) return "sidenav_base bg-sky-600"
  if (isCurrentPathPatient(url)) return "sidenav_base bg-patient-color"
  return "text-xs uppercase py-3 font-bold block text-slate-800"
}
