import React from 'react';

const FilesList = ({ hasFiles, report }) => {
  return <>
    {hasFiles && (
      <div className="my-4">
        <p className="font-semibold text-slate-600 uppercase mb-2">Existing Attachments</p>
        <div className="flex flex-row gap-x-2">
          {report.file_attachments.map((attachment) => (
            <div key={attachment.id} className="flex flex-col gap-y-2">
              <img
                src={attachment.url}
                alt={attachment.filename}
                style={{ height: '200px', width: '200px', objectFit: 'contain' }}
              />
              <p>{attachment.filename}</p>
            </div>
          ))}
        </div>
      </div>
    )}
  </>
}

export default FilesList
