import React, { useMemo } from 'react'
import { format } from "date-fns"
import Feedback from "./Feedback"
import Attachments from "./Attachments"
import PatientProfile from "./PatientProfile"
import Request from "./Request"
import Specialist from "./Specialist"

const ShowCompletedIssue = ({ issue, consult, referral, report, responses, files, caregivers, patient }) => {
  const formattedDate = useMemo(() => {
    if (consult?.created_at) return format(new Date(consult.created_at), 'yyyy-MM-dd')
    if (referral?.created_at) return format(new Date(referral.created_at), 'yyyy-MM-dd')
  }, [consult, referral])

  return (
    <div className="flex flex-wrap mt-4">
      <div className="w-full mb-12 px-4">
        {issue?.report_type !== 'no_report' && (
          <Feedback issue={issue} report={report} />
        )}
        <Attachments files={files} />
        <PatientProfile patient={patient} />
        {(consult || referral) && (
          <Request issue={issue} responses={responses} requestDate={formattedDate} />
        )}
        <Specialist issue={issue} caregivers={caregivers} consult={consult} referral={referral} />
      </div>
    </div>
  )
}

export default ShowCompletedIssue
