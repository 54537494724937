import React from "react"
import { DisplayValue } from "../../../utilities/displayValue"


const EMRFileUpload = ({ issue, onNavigate }) => {
  return (
    <tr
      className={issue?.id ? 'cursor-pointer' : 'cursor-not-allowed'}
      onClick={() => onNavigate(issue)}
    >
      <td>EMR Patient File Upload</td>
      <td>N/A</td>
      <td>N/A</td>
      <td>{DisplayValue(issue?.primary_physician.name)}</td>
      <td>{DisplayValue(issue?.primary_physician.license_number)}</td>
      <td>{DisplayValue(issue?.completed_at)}</td>
      <td>{DisplayValue(issue?.diagnosis)}</td>
      <td>
        <div className="flex justify-center align-center">
          N/A
        </div>
      </td>
    </tr>
  )
}

export default EMRFileUpload
