import React, { useEffect, useState } from 'react';
import Select from "react-select";
import axios from "axios";
import CaregiverSelectOption from "../../shared_components/caregiverOption";

function ReassignModal({ showModal, setShowModal, issue }) {
  const [specialistOptions, setSpecialistOptions] = useState([])
  const [selectedCaregiver, setSelectedCaregiver] = useState({})

  useEffect(() => {
    axios.get('/available_caregivers').then((res) => {
      const formattedOptions = res.data.map(caregiver => {
        return {
          label: `${caregiver.title} ${caregiver.first_name} ${caregiver.last_name}`,
          value: caregiver.id,
          headshot: caregiver.headshot,
          subspecialties: caregiver.get_subspecialties,
          location: caregiver.get_location
        }
      })

      setSpecialistOptions(formattedOptions)
    })
  }, [])

  const reassignIssue = () => {
    axios.put(`/caregivers/issues/${issue.id}/reassign`, { caregiver_id: selectedCaregiver.value }).then((res) => {
      setShowModal(false)
      window.location.href = res.data.path
    })
  }

  return (
    <div className={`relative z-10 ${showModal ? 'block' : 'hidden'}`} aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity ease-out duration-300" aria-hidden="true"></div>
      <div className="fixed inset-0 z-10 w-screen overflow-y-auto h-full">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all w-1/2">
            <div className="flex flex-col">
              <div className="bg-sky-600 text-white text-center py-3 uppercase lg:inline-block font-semibold ">Reassign Issue</div>
              <div className='p-6 bg-slate-100'>
                <label>Please select a specialist</label>
                <Select
                  options={specialistOptions}
                  onChange={setSelectedCaregiver}
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: base => ({
                      ...base,
                      zIndex: 11
                    })
                  }}
                  name='specialist'
                  required
                  formatOptionLabel={caregiver => <CaregiverSelectOption caregiver={caregiver} />}
                />
                <div className="px-4 py-3 flex mt-4 w-1/2 gap-x-4 mx-auto">
                  <button type="button" className="btn btn-disabled" onClick={()=>setShowModal(false)}>Cancel</button>
                  <button type="button" className="btn btn-default w-full" onClick={reassignIssue}>Reassign Issue</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReassignModal
