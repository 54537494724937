import React from 'react'
import Image from '../../../assets/images/missing_patients.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
const Onboarding = ({ url, title }) => {
  return (
    <div className='bg-white border border-gray-300 max-w-md flex flex-col mx-auto mt-24 relative rounded-md'>
      <div className='pt-4 px-24 flex flex-col items-center pb-12'>
        <p className='text-md text-center '>No {title} to Display</p>
        <p className='text-sm text-center '>
          Let's get started by adding {title}
        </p>
        <div className='p-3'>
          <img src={Image} className='w-32' />
        </div>
        <a
          className='rounded text-white flex flex-row items-center align-middle inline-block text-lg transform hover:scale-105 transition ease-in duration-300 hover:shadow-lg px-4 py-2 bg-blue-900 leading-none border border-blue-900  mt-4 lg:mt-0'
          href={`${url}`}
        >
          <FontAwesomeIcon icon={faPlusCircle} className='text-lg' />
          <p className='ml-2 hidden md:block'>Add {title}</p>
        </a>
      </div>
    </div>
  )
}
export default Onboarding
