import React from "react";

export default function HeaderStats({ backgroundColor }) {
  return (
    <>
      <div className={`relative ${backgroundColor} md:pt-32 pb-32 pt-12`}>
        <div className="px-4 md:px-10 mx-auto w-full">
        </div>
      </div>
    </>
  );
}
