import React, { useEffect, useState } from 'react'
import Select from "react-select";
import Anatomy from "./anatomy";

const AnatomyInput = ({ defaultBodyImage, defaultMarkers, name, value }) => {
  let [markers, setMarkers] = useState(value && JSON.parse(value)?.markers || []);
  let [bodyImage, setBodyImage] = useState(value && JSON.parse(value)?.bodyImage || 'anatomy');
  let [anatomyValue, setAnatomyValue] = useState()

  useEffect(() => {
    setAnatomyValue(JSON.stringify({ bodyImage: bodyImage, markers: markers }))
  }, [bodyImage, markers])

  const options = {
    'anatomy': 'Non Gender Specific',
    'female': 'Female',
    'male': 'Male'
  }

  return <div className='flex flex-col items-center gap-y-2'>
    <p>Select your area(s) of concern.</p>
    <Select value={{ label: options[bodyImage], value: bodyImage }}
            options={Object.keys(options).map(key => {
              return {
                label: options[key], value: key
              }
            })}
            onChange={(selectedOption) => setBodyImage(selectedOption.value)}
            className='w-full'
    />

    <Anatomy markers={markers} setMarkers={setMarkers} bodyImage={bodyImage} isEditable={true} />

    <input name={name} type='hidden' value={anatomyValue} />
    <button className='btn btn-default btn-small' disabled={!markers.length > 0} onClick={() => setMarkers([])}>
      Reset Markers
    </button>
  </div>
}

export default AnatomyInput
