import React, { useEffect, useState } from 'react'
import Select from "react-select";
import FormatPhoneNumber from "./formatPhoneNumber";
import axios from "axios";
import {CopyParentProfile} from './copyParentProfile';


const AddressFields = ({ profile, parentProfileId, provinces, enableCopy }) => {
  let provinceOptions = provinces.map(province => {
    return { label: province.name, value: province.name }
  })

  let [parentProfile, setParentProfile] = useState()
  let [address, setAddress] = useState(profile.address1 || '')
  let [city, setCity] = useState(profile.city || '')
  let [province, setProvince] = useState(profile.province || '')
  let [phoneNumber, setPhoneNumber] = useState(profile.preferred_phone || '')

  useEffect(() => {
    parentProfileId &&
      axios
        .get(`/patient_profiles/${parentProfileId[0]}.json`)
        .then((res) => setParentProfile(res.data))
  }, [])

  const fieldSetters = [
    {
      setter: setAddress,
      value: parentProfile && parentProfile.address1,
    },
    {
      setter: setCity,
      value: parentProfile && parentProfile.city,
    },
    {
      setter: setProvince,
      value: parentProfile && parentProfile.province,
    },
    {
      setter: setPhoneNumber,
      value: parentProfile && parentProfile.preferred_phone,
    },
  ]

  return (
    <>
      <div className='grid grid-cols-2 lg:grid-cols-2 xl:grid-cols-3'>
        {parentProfile && enableCopy && (
          <CopyParentProfile
            parentProfile={parentProfile}
            fieldSetters={fieldSetters}
          />
        )}
      </div>
      <div className='grid grid-cols-1 lg:grid-cols-2 gap-x-2'>
        <div className='form-group lg:col-span-2'>
          <label>Address</label>
          <input
            type='text'
            name='patient_profile[address1]'
            value={address}
            onChange={(e) => setAddress(e.target.value)}
          />
        </div>

        <div className='form-group'>
          <label>City</label>
          <input
            type='text'
            name='patient_profile[city]'
            value={city}
            onChange={(e) => setCity(e.target.value)}
          />
        </div>
        <div className='form-group'>
          <label>Province</label>
          <Select
            options={provinceOptions}
            value={{ label: province, value: province }}
            name='patient_profile[province]'
            onChange={(selectedOption) => setProvince(selectedOption.value)}
          />
        </div>
        <div className='form-group'>
          <label>Preferred Phone #</label>
          <FormatPhoneNumber
            fieldName='patient_profile[preferred_phone]'
            value={phoneNumber}
          />
        </div>
      </div>
    </>
  )
}

export default AddressFields
