import React, { useEffect, useState } from 'react'
import FormatPhoneNumber from './formatPhoneNumber'
import axios from 'axios'
import { useDebounce } from 'use-debounce'
import { CopyParentProfile } from './copyParentProfile'

const PharmacySelect = ({ pharmacy_profile, parentProfileId, secondPharm }) => {
  let [showDropdown, setShowDropdown] = useState(false)
  let [pharmacies, setPharmacies] = useState()
  let [pharmacyName, setPharmacyName] = useState(pharmacy_profile.pharmacy_name || '')
  let [pharmacyAddress, setPharmacyAddress] = useState(
    pharmacy_profile.pharmacy_location || ''
  )
  let [pharmacyPhone, setPharmacyPhone] = useState(pharmacy_profile.pharmacy_phone || '')
  let [pharmacyFax, setPharmacyFax] = useState(pharmacy_profile.pharmacy_fax || '')
  let [debouncedPharmacyName] = useDebounce(pharmacyName, 500)
  let [parentProfile, setParentProfile] = useState()

  const selectPharmacy = (pharmacy) => {
    setPharmacyName(pharmacy.name)
    setPharmacyAddress(pharmacy.address)
    setPharmacyPhone(pharmacy.phone_number)
    setPharmacyFax(pharmacy.fax_number)

    setShowDropdown(false)
  }

  const fieldSetters = [
    {
      setter: setPharmacyName,
      value:
        parentProfile &&
        parentProfile.pharmacy_patient_profiles.map(
          (pharmacyProfile) => pharmacyProfile.pharmacy_name
        ),
    },
    {
      setter: setPharmacyPhone,
      value:
        parentProfile &&
        parentProfile.pharmacy_patient_profiles.map(
          (pharmacyProfile) => pharmacyProfile.pharmacy_phone
        ),
    },
    {
      setter: setPharmacyAddress,
      value:
        parentProfile &&
        parentProfile.pharmacy_patient_profiles.map(
          (pharmacyProfile) => pharmacyProfile.pharmacy_location
        ),
    },
    {
      setter: setPharmacyFax,
      value:
        parentProfile &&
        parentProfile.pharmacy_patient_profiles.map(
          (pharmacyProfile) => pharmacyProfile.pharmacy_fax
        ),
    },
  ]

  const PharmacyOption = ({ pharmacy }) => {
    return (
      <div
        onMouseDown={() => selectPharmacy(pharmacy)}
        className='p-2 hover:bg-slate-100 cursor-pointer'
      >
        <div className='flex flex-row justify-between'>
          <label>{pharmacy.name}</label>
          <div className='text-xs text-slate-600'>
            {pharmacy.city}, {pharmacy.province}
          </div>
        </div>
        <div className='text-xs text-slate-500'>{pharmacy.address}</div>
      </div>
    )
  }

    useEffect(() => {
      parentProfileId &&
        axios
          .get(`/patient_profiles/${parentProfileId[0]}.json`)
          .then((res) => setParentProfile(res.data))
    }, [])

  useEffect(() => {
    axios
      .get(`/pharmacies.json?query=${debouncedPharmacyName}`)
      .then((res) => setPharmacies(res.data))
  }, [debouncedPharmacyName])

  const PharmaciesList = () => {
    if (showDropdown && pharmacies && pharmacies.length > 0) {
      return (
        <div className='absolute top-16 bg-white z-10 border border-slate-200 w-full shadow-lg overflow-scroll max-h-64'>
          {/* Clicking on this div is not firing selectPharmacy */}
          {pharmacies.map((pharmacy) => (
            <PharmacyOption key={pharmacy.id} pharmacy={pharmacy} />
          ))}
        </div>
      )
    } else {
      return null
    }
  }
  return (
    <div className="pb-2">
      {parentProfile && <CopyParentProfile parentProfile={parentProfile} fieldSetters={fieldSetters} secondPharm={secondPharm} />}
      <div className='form-group relative'>
        <label>Pharmacy Name</label>
        <input
          autoComplete='none'
          type='text'
          name='patient_profile[pharmacy_patient_profiles_attributes][][pharmacy_name]'
          value={pharmacyName}
          onKeyDown={(e) =>
            e.key === 'Escape' ? setShowDropdown(false) : null
          }
          onChange={(e) => setPharmacyName(e.target.value)}
          onBlur={() => setShowDropdown(false)}
          onFocus={() => setShowDropdown(true)}
        />
        <PharmaciesList />
      </div>
      <div className='form-group'>
        <label>Pharmacy Address</label>
        <input
          autoComplete='none'
          type='text'
          name='patient_profile[pharmacy_patient_profiles_attributes][][pharmacy_location]'
          value={pharmacyAddress}
          onChange={(e) => setPharmacyAddress(e.target.value)}
        />
      </div>

      <div className='flex flex-col md:flex-row justify-between gap-x-2'>
        <div className='form-group'>
          <label>Pharmacy Phone #</label>
          <FormatPhoneNumber
            fieldName='patient_profile[pharmacy_patient_profiles_attributes][][pharmacy_phone]'
            value={pharmacyPhone}
          />
        </div>
        <div className='form-group'>
          <label>Pharmacy Fax #</label>
          <FormatPhoneNumber
            fieldName='patient_profile[pharmacy_patient_profiles_attributes][][pharmacy_fax]'
            value={pharmacyFax}
          />
        </div>
      </div>
      <input
        name='patient_profile[pharmacy_patient_profiles_attributes][][id]'
        type='hidden'
        value={pharmacy_profile.id}
      />
    </div>
  )
}

export default PharmacySelect
