import React, { useEffect, useMemo, useState } from 'react'
import { upperCase } from "lodash"
import InfoGroup from "./InfoGroup"

const Feedback = ({ issue, report }) => {
  const [reportContent, setReportContent] = useState([])
  const reportHeading = useMemo(() => {
    let type = null
    if (issue?.report_type === 'consult') return upperCase('Consultation')
    if (issue?.report_type === 'referral') return upperCase('Referral')
    return type
  }, [issue])

  useEffect(() => {
    if (!report) return null

    if (issue.report_type === 'consult') {
      const consultContent = [
        { key: 'Impression', value: report.impression },
        { key: 'Note', value: report.note },
        { key: 'Suggestion', value: report.suggestions }
      ]
      setReportContent(consultContent)
    }

    if (issue.report_type === 'referral') {
      const referralContent = [
        { key: 'Diagnosis', value: report.diagnosis },
        { key: 'Treatment', value: report.treatment },
        { key: 'Next Steps', value: report.next_steps }
      ]
      setReportContent(referralContent)
    }
  }, [issue, report])

  return (
    <div className="w-full mb-8 pb-4 rounded shadow-lg">
      <h2 className="text-center mb-4 py-1 rounded-t bg-blue-100">{reportHeading}</h2>
      <div className="flex flex-col px-8 w-full">
        {!report && (
          <p className="text-center py-2">No Reports</p>
        )}
        {report && reportContent.map((item, index) =>
          <InfoGroup key={item.key} label={item.key} value={item.value} />
        )}
      </div>
    </div>
  )
}

export default Feedback
