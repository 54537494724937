import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/free-solid-svg-icons'

export function CopyParentProfile({ parentProfile, fieldSetters, secondPharm }) {
  const copyFields = (fieldSetters) => {
    fieldSetters.map((object) => {
      if (typeof object.value !== "object") return object.setter(object.value)
      if (secondPharm) {
        return object.value.map((value) => {
          object.setter(value)
        })
      }
      return object.setter(object.value[0])
    })
  }
  return (
    <div
      key={parentProfile.id}
      onClick={() => copyFields(fieldSetters)}
      className='items-center text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-sky-600 bg-sky-200 uppercase last:mr-0 mr-1 cursor-pointer'
    >
      <FontAwesomeIcon icon={faDownload} className={'mr-2'} />
      {` Copy from ${parentProfile.first_name}`}
    </div>
  )
}
