import React from "react";

const TableHeader = ({areAllSelected, onSelectAll}) => {
  return (
    <thead>
    <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
      <th>
        <div className='flex justify-start'>
          <input type='checkbox' value='isSelectAll'
                 checked={areAllSelected} onChange={onSelectAll}
                 className='h-5 w-6'
          />
        </div>
      </th>
      <th>Patient Information</th>
      <th>Referring HCP</th>
      <th>Consultant</th>
      <th>
        Date of Consult
      </th>
      <th>
        Diagnosis
      </th>
    </tr>
    </thead>
  )
}

export default TableHeader
