import React, { useMemo } from 'react'
import { isEmpty, upperCase } from "lodash"
import Anatomy from "../../../patients/issues/anatomy"
import InfoGroup from "./InfoGroup"

const Request = ({ issue, responses, requestDate }) => {
  const formattedResponses = useMemo(() => {
    const CAREGIVER_ISSUE_TYPES = ['patient_history', 'current_treatment', 'past_treatment', 'additional_notes', 'presumptive_diagnosis']
    const obj = {}
    responses.forEach(response => {
      const key = response['question_key']
      if (obj.hasOwnProperty(key)) return

      obj[key] = { value: response['value'] }

      if (!CAREGIVER_ISSUE_TYPES.includes(key)) {
        return obj[key] = { ...obj[key], type: issue?.issue_type }
      }

      obj[key] = { ...obj[key], type: 'caregiver_impression' }
    })
    return obj
  }, [issue, responses])

  const requestHeading = useMemo(() => {
    if (issue?.report_type === 'consult') return upperCase('Consultation Request')
    if (issue?.report_type === 'referral') return upperCase('Referral Request')
    return null
  }, [issue])

  const diagram = useMemo(() => {
    if (!formattedResponses['body_markers']) return null
    return JSON.parse(formattedResponses['body_markers'].value)
  }, [formattedResponses])

  return (
    <div className="w-full mb-8 pb-4 rounded shadow-lg">
      <h2 className="text-center mb-4 py-1 rounded-t bg-blue-100">{requestHeading}</h2>
      <div className="px-8 w-full">
        {isEmpty(formattedResponses) && (
          <div className="flex w-full justify-center">
            <p className="py-2">No Responses</p>
          </div>
        )}
        {!isEmpty(formattedResponses) && (
          <div className="flex flex-col">
            <div className="flex flex-col md:flex-row">
              <div className="inline-flex flex-col w-full md:w-1/2 md:gap-x-8">
                <InfoGroup label='Request Date' value={requestDate} widthDenominator={3} />
                <InfoGroup label='Problem Duration' value={formattedResponses['duration']?.value} widthDenominator={3} />
                <InfoGroup label='Focused Patient History' value={formattedResponses['patient_history']?.value} widthDenominator={3} />
              </div>
              <div className="inline-flex flex-col w-full md:w-1/2 md:gap-x-8">
                <InfoGroup label='Current Treatment' value={formattedResponses['current_treatment']?.value} widthDenominator={3} />
                <InfoGroup label='Past Treatment' value={formattedResponses['past_treatment']?.value} widthDenominator={3} />
                <InfoGroup label='Additional Notes' value={formattedResponses['additional_notes']?.value} widthDenominator={3} />
                <InfoGroup label='Presumptive Diagnosis' value={formattedResponses['presumptive_diagnosis']?.value} widthDenominator={3} />
              </div>
            </div>
            {diagram && (
              <Anatomy bodyImage={diagram.bodyImage} markers={diagram.markers} isEditable={false} />
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default Request
