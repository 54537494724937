import React, { useEffect, useState } from 'react'
import Select from 'react-select'

export default function DurationSelects({ question, name, required, value }) {
  const numberOptions = question.numberOptions.map(option => {
    return { label: option, value: option }
  })
  const lengthOptions = question.lengthOptions.map(option => {
    return { label: option, value: option }
  })

  const [number, setNumber] = useState(value && value[0] || '')
  const [length, setLength] = useState(value && value[1] || '')
  const [duration, setDuration] = useState('')

  useEffect(() => {
    setDuration(`${number} ${length}`)
  }, [number, length])

  return (
    <div className='flex flex-wrap gap-2'>
      <Select
        options={numberOptions}
        className='w-full md:w-64'
        name='number'
        required={required}
        menuPortalTarget={document.body}
        menuPosition={'fixed'}
        onChange={(selectedOption) => setNumber(selectedOption.value)}
        value={value && numberOptions.find(function (option) {
          return option.value === number
        })}
      />
      <Select
        options={lengthOptions}
        className='w-full md:w-64'
        name='length'
        required={required}
        menuPortalTarget={document.body}
        menuPosition={'fixed'}
        onChange={(selectedOption) => setLength(selectedOption.value)}
        value={value && lengthOptions.find(function (option) {
          return option.value === length
        })}
      />
      <input name={name} type='hidden' value={duration} />
    </div>
  )
}
