import React, { useEffect, useState } from 'react'
import axios from "axios";
import IssueQuestion from "./issueQuestion";

const Questions = ({ issueType, count, onSelect = null, draftResponses }) => {
  let [questions, setQuestions] = useState()

  useEffect(() => {
    axios.get(`/issue_types/questions?issue_type=${issueType}`).then(res => setQuestions(res.data))
  }, [])

  return <div className='flex flex-col'>
    {questions?.map((question, index) => <IssueQuestion key={question.key} i={index + count} question={question} onSelect={onSelect} draftValue={draftResponses && draftResponses[index + count]} />)}
  </div>
}

export default Questions
