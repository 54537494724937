import React from 'react'

const SubmitButton = ({ disabled, reportType }) => {
  return (
    <div className="bg-slate-100 flex flex-row p-4 items-center justify-center">
      <button disabled={disabled} className='btn btn-default w-full lg:w-auto'>
        {`Submit ${reportType} Request`}
      </button>
    </div>
  )
}

export default SubmitButton
