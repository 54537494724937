import React from 'react'

// Removed from frontend in https://github.com/pixeltree/consultmd/pull/475
const SearchBar = () => {

  const handleSubmit = (e) => {
    e.preventDefault()

    if (e.key === 'Enter') {
      window.location.href = `/caregivers/search?query=${e.target.value}`
    }
  }
  return (
    <div className='form-group px-4'>
      <label className='form-group label'>Search</label>
      <input
        placeholder='Search by keyword'
        className='p-3 border border-gray-200 rounded-md'
        onKeyUp={(e) => handleSubmit(e)}
      />
    </div>
  )
}
export default SearchBar
