import React from 'react'
import Logo from '../../../assets/images/consultMD_logo.png'

const Header = () => {
  return(
    <nav className="flex items-center justify-between flex-wrap bg-blue-100 p-6">
      <div className="flex items-center flex-no-shrink text-white mr-6">
        <a href='/'><img src={Logo} className='h-7'/></a>
      </div>
      <div className="w-full block flex-grow lg:flex lg:justify-end lg:items-center lg:w-auto">
        <div className='lg:px-2 '>
          <a href="/caregivers/sign_in"
             className="inline-block text-lg transform hover:scale-105 transition ease-in duration-300 hover:shadow-lg px-4 py-3 bg-blue-900 leading-none border rounded text-white border-blue-900  mt-4 lg:mt-0">Log In</a>
        </div>
        <div className=''>
          <a href="/caregivers/sign_up"
             className="inline-block transform hover:scale-105 transition ease-in duration-300 text-lg hover:shadow-lg px-4 py-3 leading-none border rounded border-blue-500  text-blue-500  mt-4 lg:mt-0">Register</a>
        </div>
      </div>
    </nav>
  )
}

export default Header