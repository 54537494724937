import React, { useEffect, useState } from "react"
import Logo from "../../../assets/images/consultMD_logo.png"
import axios from "axios"
import NavLink from "../shared_components/navLink"
import { faPlusCircle, faSignOutAlt, faUsers } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export default function Sidebar() {
  const [collapseShow, setCollapseShow] = useState("hidden")
  const [profiles, setProfiles] = useState([])

  useEffect(() => {
    axios.get('/patient_profiles.json').then((res) => setProfiles(res.data))
  }, [])

  return (
    <>
      <nav
        className="md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl bg-white flex flex-wrap items-center justify-between relative md:w-64 z-10 py-8 pr-8">
        <div
          className="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto">
          {/* Toggler */}
          <button
            className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-opacity-0 rounded border border-solid border-opacity-0"
            type="button"
            onClick={() => setCollapseShow("bg-white m-2 py-3 px-6")}
          >
            <i className="fas fa-bars" />
          </button>
          {/* Brand */}
          <a href='/patient_profiles' className='flex items-center justify-center'>
            <img src={Logo} className='h-7' alt='ConsultMD' />
          </a>
          
          {/* Collapse */}
          <div
            className={
              "md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded " +
              collapseShow
            }
          >
            {/* Collapse header */}
            <div className="md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-slate-200">
              <div className="flex flex-wrap">
                <div className="w-6/12">
                  <a
                    className="md:block text-left md:pb-2 text-slate-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
                    href="/patient_profiles"
                  >
                    <img src={Logo} className='h-7' alt='ConsultMD' />
                  </a>
                </div>
                <div className="w-6/12 flex justify-end">
                  <button
                    type="button"
                    className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-opacity-0 border-opacity-0"
                    onClick={() => setCollapseShow("hidden")}
                  >
                    <i className="fas fa-times"></i>
                  </button>
                </div>
              </div>
            </div>
            {/* Divider */}
            <hr className="my-4 md:min-w-full hidden md:block" />
            {/* Heading */}
            <h6 className="md:min-w-full text-slate-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline">
              Profiles
            </h6>
            {/* Navigation */}
            <ul className="md:flex-col md:min-w-full flex flex-col list-none">
              {profiles?.map((profile) => (
                <NavLink
                  key={profile.id}
                  icon={faUsers}
                  text={profile.name}
                  url={`/patient_profiles/${profile.id}/issues`}
                />
              ))}
              <NavLink icon={faPlusCircle} text='Add Profile' url='/patient_profiles/new' />
            </ul>

            <hr className="my-4 md:min-w-full" />
            <ul className="md:flex-col md:min-w-full flex flex-col list-none pl-2">
              <li>
                <a
                  href='/users/sign_out'
                  data-method='delete'
                  className='text-xs uppercase py-3 font-bold block'
                >
                  <FontAwesomeIcon icon={faSignOutAlt} className='mr-2 text-sm text-red-400' />
                  Logout
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  )
}
