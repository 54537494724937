import React, { useEffect, useState } from 'react'
import ImageMarker from "react-image-marker";
import femaleImage from "./female.gif";
import maleImage from "./male.gif";
import defaultImage from "./anatomy.gif";

const Anatomy = ({ isEditable, bodyImage, markers, setMarkers, rootUrl }) => {
  let [isMounted, setIsMounted] = useState(false)

  useEffect(() => {
    setIsMounted(true)
  }, [])

  useEffect(() => {
    if (isMounted) {
      window.status = 'ready_for_export'
    }
  }, [isMounted])

  const CustomMarker = () => {
    return <div
      className="image-marker__marker image-marker__marker--default"
      data-testid="marker"
    />
  };

  const bodyImageFile = () => {
    switch (bodyImage) {
      case 'female':
        return femaleImage
      case 'male':
        return maleImage
      default:
        return defaultImage
    }
  }

  return <ImageMarker
    src={bodyImageFile()}
    markers={markers}
    onAddMarker={(marker) => isEditable && setMarkers && setMarkers((prev) => [...prev, marker])}
    markerComponent={CustomMarker}
  />


}

export default Anatomy
