import React from 'react'
import { isEmpty } from "lodash"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFileImage} from "@fortawesome/pro-solid-svg-icons"

const Attachments = ({ files }) => {
  return (
    <div className="w-full mb-8 pb-4 rounded shadow-lg">
      <h2 className="text-center mb-4 py-1 rounded-t bg-blue-100">ATTACHMENTS</h2>
      <div className="flex flex-col items-center md:flex-wrap md:flex-row px-8 w-full">
        {isEmpty(files) && (
          <div className="flex w-full justify-center">
            <p className="py-2">No Attachments</p>
          </div>
        )}
        {!isEmpty(files) && files.map(file =>
          <a href={file.path} download={file.name} key={file.name} className="inline-flex flex-col items-center mb-8 py-2 md:gap-x-8 w-full">
            <FontAwesomeIcon icon={faFileImage} size="3x" className="text-slate-400" />
            {file.name}
          </a>
        )}
      </div>
    </div>
  )
}

export default Attachments
