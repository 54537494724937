import React from 'react'
import Logo from '../../../assets/images/consultMD_logo.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import { AdminNavigation } from "../../utilities/navigation"
import ProvinceLabel from "../shared_components/provinceLabel";

const TopNav = () => {
  return (
    <div className='flex flex-auto text-gray-800'>
      <div className='flex flex-col md:flex-row items-center w-full bg-blue-100 h-full'>
        <div className='flex flex-col items-start justify-start p-6 h-20'>
          <a className='pb-1' href='/'>
            <img src={Logo} className='h-5' alt='ConsultMD' />
          </a>
          <ProvinceLabel textSize='text-xs' />
        </div>
        <div className='overflow-y-auto overflow-x-hidden w-full'>
          <ul className='flex flex-col md:flex-row md:py-6 space-y-1 md:justify-between'>
            <div className='flex flex-col md:flex-row items-start md:items-center '>
              {AdminNavigation.map((item) => {
                return <li>
                  <a
                    href={item.href}
                    className='flex items-center h-11 focus:outline-none hover:bg-blue-200 text-gray-600 border-b hover:border-blue-500 pr-6'
                  >
                    <span className='inline-flex justify-center items-center ml-4'>
                      <FontAwesomeIcon icon={item.icon} />
                    </span>
                    <span className='ml-2 font-semibold text-xs lg:text-sm tracking-wide font-sans'>
                      {item.name}
                    </span>
                  </a>
                </li>
              })}
            </div>
            <div>
              <li>
                <a
                  href='/admins/sign_out'
                  data-method='delete'
                  className='relative flex flex-row items-center h-11 focus:outline-none hover:bg-red-300 text-gray-600 hover:text-gray-200 border-b border-opacity-0 hover:border-red-500 pr-6'
                >
                    <span className='inline-flex justify-center items-center ml-4 text-red-400'>
                      <FontAwesomeIcon icon={faSignOutAlt} />
                    </span>
                  <span className='ml-2 font-semibold text-sm tracking-wide truncate font-sans'>
                      Logout
                    </span>
                </a>
              </li>
            </div>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default TopNav
