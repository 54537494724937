import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch, faFilePdf } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";

const ExportToPdf = ({ exportPath, isRemoved }) => {
  let [loading, setLoading] = useState(false)

  const getPdfUrl = () => {
    setLoading(true)
    axios.get(exportPath).then(res => {
      setLoading(false)
      window.location.href = res.data.path
    }).catch(e => {
      console.log(e)
      setLoading(false)
    })
  }

  return <>
    {loading ? <div className='btn btn-export flex flex-row whitespace-nowrap cursor-pointer'>
        <FontAwesomeIcon icon={faCircleNotch} spin className='mr-2'
        />
        Generating
      </div>
      :
      (
        <>
          {isRemoved && <div className={`btn btn-export flex flex-row whitespace-nowrap cursor-pointer`}
                              onClick={() => getPdfUrl()}>
            <FontAwesomeIcon icon={faFilePdf} className='mr-2' />Print
          </div>}
        </>
      )
    }
  </>
}

export default ExportToPdf
