import axios from "axios";

export async function createIssue(issue, formData) {
  return await axios.post(`/caregivers/issues/${issue.id}/consultation_reports.json`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export async function updateReport(issue, report, formData) {
  return await axios.put(`/caregivers/issues/${issue.id}/consultation_reports/${report.id}.json`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}
