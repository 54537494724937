import React, { useEffect, useState } from "react";

const ExportButton = ({ issues, type, path }) => {
  const [disabled, setDisabled] = useState(issues.length === 0)

  useEffect(() => {
    if(issues.length > 0) {
      setDisabled(false )
    }
    else {
      setDisabled(true)
    }
  }, [issues])


  const renderDocument = () => {
    const ids = issues.map(issue => `ids[]=${issue.id}`).join('&')
    window.location.href = path + `?${ids}`
  }

  return (
    <button disabled={disabled} className={ disabled ? 'btn btn-export capitalize opacity-50' :'btn btn-export capitalize'} onClick={() => renderDocument()}>
      {type} Export
    </button>
  )

}

export default ExportButton
