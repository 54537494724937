import React, { useState, useMemo } from 'react'
import Select from 'react-select'
import SubspecialtyRadioSelect from "./subspecialtyRadioSelect";


const SpecialtySelect = ({ specialties, subspecialties, currentSpecialty, currentSubspecialties }) => {
  const [selectedSpecialty, setSelectedSpecialty] = useState( currentSpecialty && {
    label: currentSpecialty.name,
    value: currentSpecialty.id
  })


  const specialtyOptions = useMemo(() => (
    specialties.map((specialty) => (
      { label: specialty.name, value: specialty.id }
    ))
  ), [specialties])

  const subspecialtyOptions = useMemo(() => (
    subspecialties.filter(sub => sub.parent_id === selectedSpecialty?.value)
      .map(subspecialty => (
        { label: subspecialty.name, value: subspecialty.id }
      ))
  ), [subspecialties, selectedSpecialty])

  return (
    <div className='flex flex-col items-center justify-center w-full gap-x-2'>
      <div className='flex flex-col w-full form-group'>
        <div className='flex items-center gap-x-1 text-red-700'>
          <label>Practitioner Type / Specialty</label>
          <span>*</span>
        </div>
        <Select
          className='w-full'
          options={specialtyOptions}
          value={selectedSpecialty}
          onChange={setSelectedSpecialty}
          menuPortalTarget={document.body}
          menuPosition={'fixed'}
          name="specialty"
          required
        />
      </div>

      {selectedSpecialty?.value &&
        <AdditionalSkills subspecialties={subspecialtyOptions} currentSubspecialties={currentSubspecialties} />}

      <input type='hidden'
             name={`caregiver[caregiver_specialties_attributes][][specialty_id]`}
             value={selectedSpecialty?.value}
      />
    </div>
  )
}

const AdditionalSkills = ({ subspecialties, currentSubspecialties }) => {
  return (
    <div className='flex flex-col w-full form-group'>
      <div className='flex items-center gap-x-1 text-red-700'>
        <label>Additional Skills</label>
      </div>
      {subspecialties.length > 0 ? subspecialties.map((subspecialty) => {
        return <SubspecialtyRadioSelect subspecialty={subspecialty} key={subspecialty.id}
                                        currentSubspecialties={currentSubspecialties} />
      }) : <p>No options available</p>}
    </div>
  )
}


export default SpecialtySelect
