import React, { useMemo, useState } from 'react'
import Select from 'react-select'
import {directionOptions} from "../../../utilities/sortDirectionOptions";

const SortSelect = ({ sortOptions }) => {
  const [selectedOption, setSelectedOption] = useState(null)
  const [sortDirection, setSortDirection] = useState(null)


  const options = useMemo(() => (
    sortOptions.map(option => (
      { label: option.replace("_", " "), value: option }
    ))
  ), [sortOptions])


  return (
    <div className='flex flex-col md:flex-row items-center justify-between w-full gap-x-2'>
      <div className='flex flex-col w-full form-group'>
        <label>Sort Option Select</label>
        <Select
          className='w-full'
          options={options}
          value={selectedOption}
          onChange={setSelectedOption}
          menuPortalTarget={document.body}
          menuPosition={'fixed'}
        />
      </div>
      <div className='flex flex-col w-full form-group'>
        <label>Sort Direction Select</label>
        <Select
          className='w-full'
          options={directionOptions}
          value={sortDirection}
          menuPortalTarget={document.body}
          menuPosition={'fixed'}
          isDisabled={!selectedOption}
          onChange={setSortDirection}
        />
        {!!selectedOption?.value && !!sortDirection?.value && (
          <input type='hidden' name={`${selectedOption?.value}`} value={`${sortDirection?.value}`} />
        )}
      </div>
    </div>
  )
}

export default SortSelect
