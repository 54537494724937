import React, { useMemo } from "react"
import PropTypes from "prop-types"

const InfoGroup = ({ label, value, widthDenominator = 6 }) => {
  const width = useMemo(() => selectWidth(widthDenominator), [widthDenominator])

  return (
    <div className="flex flex-col md:flex-row gap-x-8">
      <p className={`md:flex md:justify-end md:w-${width?.left} font-semibold mb-2 md:mb-4`}>
        {label}:
      </p>
      <p className={`md:w-${width.right} mb-8`}>
        {value ?? "-"}
      </p>
    </div>
  )
}

const selectWidth = (denominator) => {
  switch (denominator) {
    case 3:
      return { left: '1/3', right: '2/3' }
    case 6:
      return { left: '1/6', right: '5/6' }
    default:
      throw TypeError('Width denominator is invalid')
  }
}

InfoGroup.propTypes = {
  label: PropTypes.string,
  value: PropTypes.any,
  widthDenominator: PropTypes.number,
}

export default InfoGroup
