import React from 'react'
import { isEmpty } from "lodash"
import InfoGroup from "./InfoGroup"

const PatientProfile = ({ patient }) => {
  return (
    <div className="w-full mb-8 pb-4 rounded shadow-lg">
      <h2 className="text-center mb-4 py-1 rounded-t bg-blue-100">PATIENT INFORMATION</h2>
      <div className="flex flex-col md:flex-row px-8 w-full">
        {isEmpty(patient.profile) && (
          <div className="flex w-full justify-center">
            <p className="py-2">No Profile</p>
          </div>
        )}
        {!isEmpty(patient.profile) && (
          <>
            <div className="inline-flex flex-col w-full md:w-1/2 md:gap-x-8">
              <InfoGroup label='First Name' value={patient.profile.first_name} widthDenominator={3} />
              <InfoGroup label='Last Name' value={patient.profile.last_name} widthDenominator={3} />
              <InfoGroup label='PHN' value={patient.profile.personal_health_number} widthDenominator={3} />
              <InfoGroup label='DOB' value={patient.profile.dob} widthDenominator={3} />
            </div>
            <div className="inline-flex flex-col w-full md:w-1/2 md:gap-x-8">
              <InfoGroup label='Age' value={patient.age} widthDenominator={3} />
              <InfoGroup label='Home Phone' value={patient.profile.preferred_phone} widthDenominator={3} />
              <InfoGroup label='Other Phone' value={patient.profile.other_phone} widthDenominator={3} />
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default PatientProfile
