import React, { useEffect, useState } from 'react'
import { upperCase, startCase } from "lodash"
import InfoGroup from "./InfoGroup"

const Specialist = ({ issue, caregivers, consult, referral }) => {
  const [hasSpecialist, setHasSpecialist] = useState(false)
  const [specialist, setSpecialist] = useState({ heading: { value: 'Caregiver', upperCase: 'CAREGIVER' }, name: '' })

  useEffect(() => {
    if (issue?.report_type === 'consult') {
      const type = 'Consultant'

      setSpecialist({
        heading: { value: startCase(type), upperCase: upperCase(type) },
        name: caregivers.consulting_specialist
      })
    }
    if (issue?.report_type === 'referral') {
      const type = 'Referral'

      setSpecialist({
        heading: { value: startCase(type), upperCase: upperCase(type) },
        name: caregivers.referring_specialist
      })
    }
  }, [issue, caregivers])

  useEffect(() => {
    if (consult || referral) {
      return setHasSpecialist(true)
    }
    return setHasSpecialist(false)
  }, [consult, referral])

  return (
    <div className="w-full mb-8 pb-4 rounded shadow-lg">
      <h2 className="text-center mb-4 py-1 rounded-t bg-blue-100">{specialist.heading.upperCase}</h2>
      <div className="flex flex-col px-8 w-full">
        {hasSpecialist && <InfoGroup label={specialist.heading.value} value={specialist.name} />}
        <InfoGroup label="Primary Caregiver" value={caregivers.primary} />
      </div>
    </div>
  )
}

export default Specialist
