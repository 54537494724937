import React, { useEffect, useState } from 'react'
import Questions from "../../patients/issues/questions"
import CaregiverSelect from "../../shared_components/caregiverSelect"

const PatientFileUpload = ({ caregivers, count, issueType, reportType, specialties }) => {
  const [hasFile, setHasFile] = useState(false)
  const [hasSpecialist, setHasSpecialist] = useState(false)

  const onFileSelect = (e) => {
    if (e.target.files.length > 0) setHasFile(true)
  }

  const onSpecialistSelect = (e) => {
    if (e.value) setHasSpecialist(true)
  }

  return (
    <>
      <div className="flex flex-wrap flex-col xl:flex-row xl:items-center xl:justify-center">
        <Questions count={count} issueType={issueType} onSelect={onFileSelect} />
      </div>
      <CaregiverSelect
        caregivers={caregivers}
        reportType={reportType}
        specialties={specialties}
        isDisabled={!(hasFile && hasSpecialist)}
        onSelect={onSpecialistSelect}
      />
    </>
  )
}

export default PatientFileUpload
