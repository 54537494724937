import React, { useEffect, useRef, useState } from 'react'
import * as EmailValidator from "email-validator";
import axios from "axios";

const EmailAddress = ({ address, deleteAddress }) => {
  const emailValidIcon = () => {
    if (address.valid) {
      return <i className='fa fa-check text-green-500' title='Email is valid' />
    } else {
      return <i className='fa fa-exclamation text-red-500' title='Email is not valid' />
    }
  }

  const successfullyInvited = () => {
    if (address.errors.length > 0) {
      return false
    }
    return address.invited;
  }

  return (
    <div className="flex flex-row justify-between">
      <div>{emailValidIcon()} {address.email}</div>
      {!successfullyInvited() && <button onClick={() => deleteAddress(address.email)}>Remove</button>}
      {successfullyInvited() && <div className='text-sm'>Successfully Invited!</div>}
      {address.errors.length > 0 && <div className='text-sm'>{address.errors.join(', ')}</div>}
    </div>
  );
};

const InvitePatients = ({ inviteUrl }) => {
  let emailField = useRef();
  let [addressesStatus, setAddressesStatus] = useState({});
  let [input, setInput] = useState("");
  let [submitPossible, setSubmitPossible] = useState(false);

  useEffect(() => {
    const addressesExist = Object.keys(addressesStatus).length !== 0;

    if (addressesExist && validateEmails(addressesStatus)) {
      setSubmitPossible(true);
    } else {
      setSubmitPossible(false);
    }
  }, [addressesStatus]);

  const invitePatients = () => {
    const emails = Object.keys(addressesStatus);

    emails.forEach((email) => {
      //axios request to send email invite
      axios.post(inviteUrl, { user: { email: email } })
        .then(() => {
          const updatedAddressesStatus = { ...addressesStatus };
          updatedAddressesStatus[email].invited = true;
          setAddressesStatus(updatedAddressesStatus);
        })
        .catch(e => {
          const updatedAddressesStatus = { ...addressesStatus };
          updatedAddressesStatus[email].invited = true
          updatedAddressesStatus[email].errors = e.response.data
          setAddressesStatus(updatedAddressesStatus)
        })
    });
  };

  const validateEmails = function (emails) {
    for (let key in emails) {
      if (!emails[key].valid) {
        return false;
      }
    }
    return true;
  };

  const parseEmailAddresses = (data) => {
    let newAddresses = { ...addressesStatus };
    data.split(",").forEach((item) => {
      const trimmedEmail = item.trim().toLowerCase();

      if (!newAddresses[trimmedEmail]) {
        newAddresses[trimmedEmail] = {
          email: trimmedEmail,
          valid: EmailValidator.validate(trimmedEmail),
          invited: false,
          errors: []
        };
      }
    });

    setAddressesStatus(newAddresses);
    setInput("");
  };

  const deleteAddress = function (email) {
    const newAddresses = { ...addressesStatus };
    delete newAddresses[email];

    setAddressesStatus(newAddresses);
  };

  const handleKeyPress = function (event) {
    if (event.which === 13) {
      parseEmailAddresses(input);
    }
  };

  return <>
    <div className="flex flex-wrap">
      <div className="w-full mb-4 px-4">
        <h2 className="uppercase text-slate-600 mb-4 font-semibold">
          Enter the emails of the patient you want to invite.
        </h2>
        <p className='mb-2 text-sm'>To add multiple emails, enter them with commas. i.e. bob@example.com,
          sue@example.com</p>
        <p className='mb-2 text-sm'>Once all the emails on this list are valid, click on Invite to send invite emails
          to all
          the patients.</p>

        <div className='flex flex-row gap-x-2 my-4'>
          <input
            type="text"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            ref={emailField}
            onKeyUp={handleKeyPress}
            className="block p-3 w-full text-gray-700 appearance-none focus:outline-none border border-gray-200 focus:shadow-inner m-0 rounded"
          />
          <button className='btn btn-default' onClick={() => parseEmailAddresses(input)}>Add</button>
        </div>
        <div className="flex flex-col gap-y-1 my-2">
          {Object.keys(addressesStatus).map((key) => (
            <EmailAddress
              key={key}
              address={addressesStatus[key]}
              deleteAddress={deleteAddress}
            />
          ))}
          <button disabled={!submitPossible} className='btn btn-default' onClick={() => invitePatients()}>Send
            Invitations
          </button>
        </div>
      </div>
    </div>
  </>
}

export default InvitePatients;
