import React, { useState } from 'react'
import Switch from 'react-switch'
import { getColor } from '../../utilities/colors'

const ListedToggle = () => {
  const [toggled, setToggled] = useState(false)

  let color = 'blue'
  let onColor = `bg-${color}-200`
  let onHandleColor = `bg-${color}-500`
  let offColor = `bg-gray-200`
  let offHandleColor = 'bg-white'

  return (
    <div>
      <Switch
        onChange={setToggled}
        checked={toggled}
        onColor={getColor(onColor)}
        onHandleColor={getColor(onHandleColor)}
        offColor={getColor(offColor)}
        offHandleColor={getColor(offHandleColor)}
        handleDiameter={24}
        uncheckedIcon={false}
        checkedIcon={false}
        boxShadow='0px 1px 5px rgba(0, 0, 0, 0.2)'
        activeBoxShadow='0px 1px 5px rgba(0, 0, 0, 0.2)'
        height={20}
        width={48}
        className='react-switch'
      />
      <input
        name='comment[allow_response]'
        type='hidden'
        value={JSON.stringify(toggled)}
      />
    </div>
  )
}

export default ListedToggle
