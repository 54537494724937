import React from 'react'

const ConsultDermNotice = () => {
  return (
    <>
      {process.env.REACT_APP_PROVINCE === 'BRITISH COLUMBIA' && (
        <div className="pt-6 flex flex-col gap-4">
          <p>
            To review old consult history, please click on the link below. Please note that the old ConsultDerm site can
            not receive new consult requests. Previous username and password are unchanged
          </p>
          <a className='underline font-bold' href='https://bc.consultderm.com/Login2.aspx'>B.C ConsultDerm site - Click here</a>
        </div>
      )}
    </>
  )
}

export default ConsultDermNotice
