import React from "react"
import EMRFileUpload from "./EMRFileUpload"
import IssueRow from "./IssueRow"

const TableRow = ({ issue, isSelected, selectIssue, deselectIssue }) => {

  const toggleSelectIssue = () => {
    if (!isSelected) {
      selectIssue(issue)
    } else {
      deselectIssue(issue)
    }
  }
  return (
    <>
      {issue.issue_type === 'patient_file_upload' ? (
          <EMRFileUpload issue={issue} onNavigate={onNavigate} />
        ) :
        <IssueRow issue={issue} isSelected={isSelected} toggleSelectIssue={toggleSelectIssue} onNavigate={onNavigate} />
      }
    </>
  )
}

const onNavigate = (issue) => {
  const { origin, pathname, } = window.location
  if (issue?.id) {
    window.open(`${origin + pathname}/${issue.id}`, "_blank", "noopener=true,noreferrer=100")
  }
}

export default TableRow
