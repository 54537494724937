import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faEdit, faTimes } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'

const EditImpression = ({ updatePath, issue, issue_type, className }) => {
  let [impression, setImpression] = useState(issue.impression || '')
  let [isEditing, setIsEditing] = useState(false)

  const doUpdate = () => {
    axios.put(updatePath, { issue: { impression: impression } }).then((res) => {
      setImpression(res.data.impression)
      setIsEditing(false)
    })
  }

  return (
    <div className='mb-1'>
      {isEditing ? (
          <div className='flex flex-row'>
            <div>
              <input
                type='text'
                className='px-3 py-1 text-slate-600 relative bg-white bg-white rounded text-sm border border-slate-200 outline-none focus:outline-none'
                defaultValue={impression}
                maxLength="255"
                onChange={(e) => setImpression(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') doUpdate()
                  if (e.key === 'Escape') setIsEditing(false)
                }}
              />
              <div className='flex flex-row justify-between text-xs mt-1.5 px-1.5'>
                <p>Max 255 characters</p><p>{impression.length}</p>
              </div>
            </div>
            <div>
              <FontAwesomeIcon
                icon={faCheck}
                className='ml-2 text-xs text-green-400 hover:text-green-600 cursor-pointer'
                onClick={() => doUpdate()}
              />
              <FontAwesomeIcon
                icon={faTimes}
                className='ml-2 text-xs text-red-400 hover:text-red-600 cursor-pointer'
                onClick={() => setIsEditing(false)}
              />
            </div>
          </div>
      ) : (
        <div className='items-center flex flex-row'>
          <span className={className ? className :'text-xs overflow-ellipsis'}>
            {!impression ? `Initial Impression: ${issue_type}` : impression}
          </span>
          <FontAwesomeIcon
            icon={faEdit}
            className='ml-2 text-xs text-slate-400 hover:text-slate-600 cursor-pointer'
            onClick={() => setIsEditing(true)}
          />
        </div>
      )}
    </div>
  )
}

export default EditImpression
