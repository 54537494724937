import React, { useState } from 'react'
import Select from 'react-select'
import { format } from 'date-fns'

const InitialConsultForm = ({ patientProfiles, issues, uploadRoute, flowType }) => {
  const [isExisting, setIsExisting] = useState(false)

  const PatientAndIssueSelects = () => {
    const [selectedPatientProfile, setSelectedPatientProfile] = useState(null)
    const [selectedIssue, setSelectedIssue] = useState(null)

    const optionsPatientProfile = patientProfiles.map(patientProfile => {
      return { label: `${patientProfile.first_name} ${patientProfile.last_name}`, value: patientProfile.id }
    })
    const issuesOptions = issues.filter((issue) => issue.patient_profile_id === selectedPatientProfile?.value && issue.is_open === true).map(issue => {
      let formatDate = format(new Date(issue.created_at), 'yyyy-MM-dd')
      return {label: issue.impression ? `${issue.impression} -  ${formatDate}` : `${issue.issue_type.replaceAll("_", " ")} - ${formatDate}`, value: issue.id}
    })

    return (
      <>
        <div className='flex flex-col xl:flex-row gap-y-4 items-center justify-between pt-6'>
          <Select options={optionsPatientProfile} className='w-full mr-1 px-4' placeholder='Existing ConsultMD patient'
                  onChange={setSelectedPatientProfile} />
          <Select options={issuesOptions} className='w-full capitalize mr-1 px-4' placeholder='Select Existing Issue'
                  onChange={setSelectedIssue} isDisabled={!selectedPatientProfile} />
        </div>
        {selectedIssue && (
          <div className='flex justify-center text-center'>
            <a
              className='btn btn-secondary mt-16 w-64'
              href={`/caregivers/${flowType}/issues/${selectedIssue.value}/edit`}
            >
              Submit
            </a>
          </div>
        )}
      </>
    )
  }

  return (
    <>
      <div className='flex flex-col lg:flex-row items-center justify-center gap-y-2 xl:gap-x-4 w-full'>
        <button onClick={() => setIsExisting((prev) => !prev)} className='btn btn-secondary w-full text-xl xl:text-2xl uppercase'>ConsultMD Patient
        </button>
        <a href={`${uploadRoute}`} className='btn btn-secondary text-center w-full text-xl xl:text-2xl uppercase'>Non-ConsultMD patient</a>
      </div>
      {isExisting && <PatientAndIssueSelects />}
    </>
  )
}

export default InitialConsultForm
