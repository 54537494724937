import React from 'react';

const TextInput = ({ title, label, setLabel }) => {
  return(
    <div className="flex flex-col gap-x-1 mb-1">
      <div className="mb-1 font-semibold text-slate-600 uppercase">
        <label>{title}</label>
        <span className="text-red-700">*</span>
      </div>
      <textarea
        className="px-3 py-3 placeholder-slate-400 text-slate-600 relative bg-white rounded text-sm border border-slate-300 outline-none focus:outline-none w-full"
        value={label}
        onChange={(e) => setLabel(e.target.value)}
      />
    </div>
  )
}

export default TextInput
