import React from "react";

const DoctorAvatar = () => {
  return (
    <div>
      <span className="fa-stack fa-2x">
        <i className="fa fa-circle fa-stack-2x text-orange-300"></i>
        <i className="fa fa-user fa-stack-1x"></i>
      </span>
    </div>
  )
}

const CaregiverSelectOption = ({ caregiver }) => {
  return (
    <div className="flex items-center flex-wrap justify-start">
      {caregiver.headshot
        ? (
          <div className='px-2'>
            <img src={caregiver.headshot} alt="physician-image" className='w-16 h-16 rounded-full object-cover' />
          </div>
        )
        : <DoctorAvatar />
      }
      {caregiver.label === 'Next Available'
        ? <div><span className="font-semibold px-2">{caregiver.label}</span></div>
        : (
          <div>
            <span className='font-semibold px-2'>{caregiver.label}</span>
            <span>|</span>
            <span className='font-light px-2'>Additional Skills:</span>
            <span className='font-semibold px-1'>{
              typeof caregiver.subspecialties !== 'string' ? (
                <>
                  {
                    caregiver.subspecialties.map((subspecialty, index) => {
                      return <span key={subspecialty.id}>{(index ? ', ' : '') + subspecialty.name}</span>
                    })
                  }

                </>
              ): <>{caregiver.subspecialties} </>
            }</span>
            <span>|</span>
            <span className='font-semibold px-2'>{caregiver.location}</span>
          </div>
        )
      }
    </div>
  )
}

export default CaregiverSelectOption
