import React from 'react'

const Card = ({ img_name, title, description }) => {
  return (
    <div
      style={{ minHeight: '350px' }}
      className=' w-full cursor-pointer hover:shadow-xl py-4 px-8 bg-white shadow-lg rounded-2xl my-20 '
    >
      <div className='flex justify-center md:justify-end -mt-16'>
        <img
          src={require(`../../../../assets/images/${img_name}`)}
          alt={'drawing by undraw'}
          className=' w-24 h-24 object-cover rounded-full border-2 border-blue-200'
        />
      </div>
      <div>
        <h2 className='text-gray-800 text-3xl font-semibold py-2'>{title}</h2>
        <hr className='py-1' />
        <p className=' mt-2 text-lg text-gray-600 p-2'>{description}</p>
      </div>
    </div>
  )
}

export default Card
