import React from 'react'

const ProvinceLabel = ({textSize}) => {
  return(
    <p className={`font-semibold ${textSize} text-gray-700`}>
      {process.env.REACT_APP_PROVINCE}
    </p>
  )
}

export default ProvinceLabel
