import React, { useEffect, useMemo, useState } from 'react'
import Select from 'react-select'
import CaregiverSelectOption from "./caregiverOption";

const CaregiverSelect = ({ specialties, caregivers, reportType, isDisabled = null, onSelect = null, draftSpecialty, draftSpecialistID }) => {
  const [selectedSpecialty, setSelectedSpecialty] = useState(draftSpecialty && {label: draftSpecialty?.name, value: draftSpecialty?.id})

  const getCurrentPhysician = () => {
    const caregiver = caregivers.filter((c) => c.id === draftSpecialistID)[0]
    return {
      label: `${caregiver.title} ${caregiver.first_name} ${caregiver.last_name}`,
      value: caregiver.id,
      headshot: caregiver.headshot,
      subspecialties: caregiver.get_subspecialties,
      location: caregiver.get_location
    }
  }

  const [selectedCaregiver, setSelectedCaregiver] = useState(draftSpecialistID && getCurrentPhysician())

  const specialtyOptions = useMemo(() => (
    specialties.map(specialty => (
      { label: specialty.name, value: specialty.id }
    )).filter(specialty => specialty.label === 'Dermatology')
  ), [specialties])

  const caregiverOptions = useMemo(() => (
    [{ label: 'Next Available', value: null, headshot: null, subspecialty: null, location: null }, ...caregivers
      .filter(caregiver => caregiver.get_specialty?.id === selectedSpecialty?.value)
      .map(caregiver => (
        {
          label: `${caregiver.title} ${caregiver.first_name} ${caregiver.last_name}`,
          value: caregiver.id,
          headshot: caregiver.headshot,
          subspecialties: caregiver.get_subspecialties,
          location: caregiver.get_location
        }
      ))
    ]
  ), [caregivers, selectedSpecialty])


  useEffect(() => {
    if (onSelect && selectedCaregiver) onSelect(selectedCaregiver)
  }, [selectedCaregiver])

  return (
    <>
      <div className='flex flex-col items-center justify-between w-full gap-x-2'>
        <div className='flex flex-col w-full form-group pb-6'>
          <label className='pb-2'>1. Please select the appropriate specialty for this consultation</label>
          <div className='flex items-center gap-x-1 text-red-700'>
            <label>Specialty Select</label>
            <span>*</span>
          </div>
          <Select
            className='w-full'
            options={specialtyOptions}
            value={selectedSpecialty}
            onChange={setSelectedSpecialty}
            menuPortalTarget={document.body}
            menuPosition={'fixed'}
            name="specialty"
            required
          />
        </div>
        <hr className='border-1 border-gray-300 w-full' />
        <div className='flex flex-col w-full form-group pt-6'>
          <label className='pb-2'>2. Please select a specific consultant or have your request go to the first available
            consultant.</label>
          <div className='flex items-center gap-x-1 text-red-700'>
            <label>Physician Select</label>
            <span>*</span>
          </div>
          <Select
            className='w-full'
            styles={customStyles}
            options={caregiverOptions}
            onChange={setSelectedCaregiver}
            value={selectedCaregiver || caregiverOptions[0]}
            menuPortalTarget={document.body}
            menuPosition={'fixed'}
            isDisabled={!selectedSpecialty}
            name='specialist'
            required
            formatOptionLabel={caregiver => <CaregiverSelectOption caregiver={caregiver} />}
          />
        </div>
        <input type='hidden'
               name={`issue[${reportType === 'Consultation' ? 'consultations_attributes' : 'referrals_attributes'}][][specialist_id]`}
               value={selectedCaregiver?.value} />
        <input type='hidden'
               name={`issue[${reportType === 'Consultation' ? 'consultations_attributes' : 'referrals_attributes'}][][specialty_id]`}
               value={selectedSpecialty?.value} />
      </div>
    </>
  )
}

export default CaregiverSelect

const customStyles = {
  menuList: (provided) => ({
    ...provided,
    maxHeight: '525px'
  }),
};
